<template>
    <div class="common-layout">
        <el-container>
            <el-header>
                <el-menu
                    class="el-menu-demo"
                    mode="horizontal"
                    :ellipsis="false"
                    @select="headerMenuClick"
                >
                    <el-menu-item index="0">
                        <img src="@/assets/logo.png" class="cherry-logo" alt="logo">
                    </el-menu-item>
                    <div class="flex-grow" />
                    <el-sub-menu index="user">
                        <template #title>
                            {{ user.name + ' (' + company.name + ')' }}
                        </template>
                        <el-menu-item index="change">切换公司</el-menu-item>
                        <el-menu-item index="logout">退出登录</el-menu-item>
                    </el-sub-menu>
                    <el-menu-item index="menu" class="cherry-mobile-menu">
                      <el-icon :size="18" color="#000000">
                        <Menu />
                      </el-icon>
                    </el-menu-item>
                </el-menu>
            </el-header>
            <el-container>
                <el-aside class="cherry-aside" width="200">
                    <el-menu
                        :router="true"
                        :default-active="defaultRouter"
                    >
                      <div v-for="(item, key) in sidebar.data" :key="key">
                        <el-sub-menu :index="item.index" v-if="item.children && item.children.length > 0">
                          <template #title>{{ item.name }}</template>
                          <el-menu-item v-for="(val,index) in item.children" :key="index" :index="val.index" v-text="val.name"></el-menu-item>
                        </el-sub-menu>

                        <el-menu-item :index="item.index" v-else v-text="item.name"></el-menu-item>
                      </div>
                    </el-menu>
                </el-aside>
                <el-main class="cherry-main">
                    <router-view />
                </el-main>
            </el-container>
        </el-container>

      <!-- 移动端菜单-->
      <el-drawer
          v-model="sidebar.show"
          title="导航"
          :size="200"
      >
        <el-menu
            :router="true"
            :default-active="defaultRouter"
        >
            <div v-for="(item, key) in sidebar.data" :key="key">
              <el-sub-menu :index="item.index" v-if="item.children && item.children.length > 0">
                <template #title>{{ item.name }}</template>
                <el-menu-item v-for="(val,index) in item.children" :key="index" :index="val.index" v-text="val.name"></el-menu-item>
              </el-sub-menu>

              <el-menu-item :index="item.index" v-else v-text="item.name"></el-menu-item>
            </div>
        </el-menu>
      </el-drawer>
      <!-- end 移动端菜单-->


      <el-dialog v-model="change.show" title="切换公司">
        <el-table
            :data="change.tableData"
            border>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column label="操作" prop="price">
            <template v-slot="scope">
              <div v-if="scope.row.id !== company.id">
                <el-button type="danger" @click="changeCompany(scope.row)" size="small">切换</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
</template>

<script>
import {getUser} from '@/plugins/auth'
import {getCompany, getCompanyData, setCompany} from '@/plugins/company'
export default {
    name: 'Main',
    data() {
        return {
            defaultRouter: '/',
            user: {},
            company: {},
            change: {
              show: false,
              tableData: [],
            },
            sidebar: {
              show: false,
              data: [
                {index: '/', name: '首页'},
                {index: 'project', name: '项目', children: [
                    {
                      index: '/project/index',
                      name: '合同管理',
                    },
                    {
                      index: '/project/project',
                      name: '项目管理',
                    },
                    {
                      index: '/project/pay',
                      name: '收款记录',
                    },
                    {
                      index: '/project/cost',
                      name: '支出记录',
                    },
                    {
                      index: '/project/budget',
                      name: '预算模版',
                    },
                    {
                      index: '/project/person',
                      name: '收款人管理',
                    },
                    {
                      index: '/project/impress',
                      name: '备用金管理',
                    },
                  ]},
                {
                  index: 'tc', name: '提成', children: [
                    {
                      index: '/tc/index',
                      name: '提成列表',
                    },
                    {
                      index: '/tc/person',
                      name: '提成人',
                    },
                    {
                      index: '/tc/detail',
                      name: '提成明细',
                    },
                  ],
                },
                {
                  index: 'task', name: '任务', children: [
                    {
                      index: '/task/index',
                      name: '任务列表',
                    },
                    {
                      index: '/task/record',
                      name: '领取记录',
                    },
                    {
                      index: '/task/audit',
                      name: '记录审核',
                    },
                    {
                      index: '/task/merchant',
                      name: '商家链接',
                    },
                  ],
                },
                {
                  index: 'member', name: '用户管理', children: [
                    {
                      index: '/member/index',
                      name: '用户列表',
                    }
                  ],
                },
                {
                  index: 'wechat', name: '微信通知', children: [
                    {
                      index: '/message/check',
                      name: '核销通知',
                    },
                    {
                      index: '/message/group',
                      name: '通告群发',
                    },
                    {
                      index: '/message/logs',
                      name: '发送记录',
                    },
                    {
                      index: '/message/send',
                      name: '发送消息',
                    },
                  ]
                },
                {
                  index: 'numbers', name: '统计视图', children: [
                    {
                      index: '/numbers/index',
                      name: '概览',
                    },
                    {
                      index: '/numbers/padding-pay',
                      name: '待收款',
                    },
                    {
                      index: '/numbers/padding-renew',
                      name: '待续费',
                    },
                  ]
                }
              ]
            },
        }
    },
    created() {
        this.routerActive()
        this.user = getUser()
        this.change.tableData = getCompanyData()
        this.company = getCompany()
    },
    methods: {
        routerActive () {
            this.defaultRouter = this.$route.meta && this.$route.meta.active ?
                this.$route.meta.active :
                this.$route.path
        },
        changeCompany (data) {
          setCompany(data)
          this.company = getCompany()

          window.location = '/'
        },
        headerMenuClick(index) {
            if (index === 'logout') {
                this.$messageBox.confirm(
                    '确定要退出登录吗？',
                    '退出',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                    }
                ).then(() => {
                    this.$router.push({name: 'logout'})
                })
            }

            if (index === 'change') {
              this.change.show = true
            }

            if (index === 'menu') {
              this.sidebar.show = true
            }
        }
    },
    watch: {
      $route(to, from) {
        console.log(to, from)
        this.sidebar.show = false
      }
    }
}
</script>

<style scoped>
.el-header {
    padding: 0;
    height: 60px;
    line-height:58px;
}
.el-menu {
    border-right: none;
}
.cherry-logo {
    height: 40px;
    padding-top:9px;
    padding-bottom: 8px;
    padding-right: 10px;
}
.cherry-aside {
    height:100%;
    position: fixed;
    top:60px;
    width:200px;
    border-right: solid 1px #dcdfe6;
    @media screen and (max-width: 768px) {
      display: none;
    }
}
.cherry-main {
    position: fixed;
    height: calc(100% - 60px);
    width:calc(100% - 200px);
    top:60px;
    left:201px;
    overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }
}
.cherry-mobile-menu {
  display: none;
  padding-left:0;
  padding-right: 0;
  text-align: center;
  width: 50px;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.flex-grow {
    flex-grow: 1;
}
.el-sub-menu.is-active .el-sub-menu__title, .el-menu--horizontal>.el-menu-item.is-active {
     border-bottom: none;
}
</style>
